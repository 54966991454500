body {
    background-color: black;
}

.imaage-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    /* transition: 500ms all ease-in-out; */
    z-index: 6;
    grid-column-gap: 2em;
    grid-row-gap: 2em;
    flex-direction: row;
    flex: none;
    justify-content: center;
    align-items: flex-start;
    display: flex;
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
}

.Footer_main{
    background-color: white;
    padding: 50px 25px;
}

.footer_social a{
    margin: 0 5px;
}

.Footer_div h2{
    font-size: 20px;
}

.Footer_div p{
    margin: 20px 0;
    font-size: 15px;
}

.Footer_container{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
}

.Footer_div{
    width: 31%;
}

/* .imaage-container:hover {
    transform: translate3d(0px, 0px, 0px) scale3d(0.757092, 0.757092, 1) rotateX(0deg) rotateY(0deg) rotateZ(-12.1454deg) skew(0deg, 0deg); 
} */
.main_container {
    /* overflow: hidden; */
    /* padding: 70px 0; */

    z-index: 5;
    width: 100%;
    height: 100vh;
    max-width: 100vw;
    background-color: transparent;
    margin-bottom: -100vh;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
}

.image_main {
    /* transition: 400ms all ease-in-out; */
    width: 40vw;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.imag1 {
    transform: translate3d(0px, -4.8024%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
}

.imag2 {
    transform: translate3d(0px, -6.8024%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
}

.imag3 {
    transform: translate3d(0px, -8.8024%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
}

.image_main img {
    width: 40vw;
    height: 39vh;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
}

.archive-trigger {
    z-index: 4;
    height: 280vh;
    position: relative;
}

.archive-holder {
    flex-direction: column;
    display: flex;
    /* overflow: hidden; */
}

.content {
    z-index: 1;
    position: relative;
}

.archive-title-wrapper {
    z-index: 10;
    color: white;
    justify-content: center;
    align-items: center;
    display: flex;
    position: absolute;
    top: 50%;
    bottom: auto;
    left: 50%;
    right: auto;
    /* overflow: hidden; */
    transform: translate(-50%, -50%);
}
.archive-title {
    text-align: center;
    /* letter-spacing: -1vw; */
    white-space: nowrap;
    cursor: default;
    padding-top: 0.1em;
    font-size: 12vw;
    font-weight: 400;
    line-height: .8;
}

.rounded-div-wrapper {
    z-index: 20;
    height: 100px;
    margin-bottom: -1px;
    position: relative;
    overflow: hidden;
    transform: translate(0, 0);
}
.rounded-div.is-top {
    transform: translate(-50%);
}
.rounded-div {
    width: 150%;
    height: 750%;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    overflow: hidden;
    transform: translate(-50%, -86.6%);
}

.content-section {
    z-index: 10;
    background-color: white;
    position: relative;
}
/* .spacer-xlarge {
    height: 32vh;
    min-height: 20rem;
    background-color: white;
} */
.rounded-div-background {
    background-color: white;
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
}
.content-wrapper {
    width: 100%;
    padding-left: 2.5vw;
    padding-right: 2.5vw;
    position: relative;
}

.overflow-hidden {
    position: relative;
    overflow: hidden;
}

.content-wrapper.justify-vertical {
    justify-content: space-between;
    align-items: center;
    display: flex;
}

.w-layout-grid {
    grid-row-gap: 16px;
    grid-column-gap: 16px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
}

.fill-text-paragraph-grid {
    grid-column-gap: 2em;
    grid-row-gap: 2em;
    flex: 1;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    display: flex;
}
.footer_flex{
    display: flex;
    margin: 20px 0px;
    gap: 10px;
    align-items: center;
    /* justify-content: center; */
}
.footer_flex:nth-child(1){
    align-items: flex-start;
}


@media screen and (max-width: 767px) {
    .footer_flex{
        align-items: center;
        justify-content: center;
    }
    .footer_flex:nth-child(1){
        align-items: flex-start;
    }
    
    .Footer_div {
        text-align: center;
        width: 40%;
    }
}

@media screen and (max-width: 479px) {
    .Footer_div {
        /* text-align: center; */
        width: 80%;
    }
}

/* Portfolio */
.portfolio {
    color: white;
    margin-top: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .portfolio-box {
    position: relative;
    overflow: hidden;
  }
  
  .image-overlay {
    position: relative;
  }
  
  .overlay-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.5); 
    color: white;
    text-align: center;
    padding: 10px;
    font-size: 20px; /* Larger font size */
    /* opacity: 0; Initially hidden */
    opacity: 1; /* Visible by default */

    transition: opacity 0.3s ease;
  }
  
  .portfolio-box:hover .overlay-text {
    opacity: 1; /* Show overlay on hover */
  }
  
  .portfolio h2 {
    color: white;
    margin-bottom: 20px;
  }
  
  .portfolio-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  

    
  .portfolio-box {
    flex-basis: calc(25% - 20px); 
    margin: 0 10px 20px;
    text-align: center;
    background-color: white;
    padding: 10px;
    border-radius: 8px;
  }
  
  .portfolio-box img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .portfolio-box h4 {
    margin: 10px 0;
    color: black;
  }
  
  @media screen and (max-width: 768px) {
    .portfolio-box {
      flex-basis: calc(100% - 20px); /* 1 card per row for smaller screens */
    }
  }
  
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .portfolio-box {
      flex-basis: calc(50% - 20px); /* 2 cards per row for screens between 769px and 1024px */
    }
  }
  
  @media screen and (min-width: 1025px) {
    .portfolio-box {
      flex-basis: calc(25% - 20px); /* 4 cards per row for larger screens */
    }
  }
  


  
  